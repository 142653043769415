@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

body {
  text-align: center;
  font-family: 'Fjalla One', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  margin-top: 10px;
}

.title {
  font-family: "Fjalla One", sans-serif;
  font-size: 64px;
  font-weight: bold;
}

.contact-email a {
  font-family: "Fjalla One", sans-serif;
  color: #007bff;
  text-decoration: none;
}

.contact-email a:hover {
  text-decoration: underline;
}

.imagem {
  width: 50%;
  height: 50%;
  object-fit: fill;
}